import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Container, Grid } from '@material-ui/core';
import { ParallaxBanner } from 'react-scroll-parallax';
import FlashIcon from '@material-ui/icons/FlashOn';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import SearchIcon from '@material-ui/icons/Search';
import CloudIcon from '@material-ui/icons/Cloud';
import FeatureSectionLight from '../components/featureSectionLight';
import SubLayout from '../layouts/subLayout';
import ReactIcon from '../../assets/svg/react-original.svg';
import FlutterIcon from '../../assets/svg/flutter-original.svg';
import SwiftIcon from '../../assets/svg/swift-original.svg';
import AllServices from '../components/allServices';
import SEO from '../components/seo';

const AppsPage = () => {
  const data = useStaticQuery(graphql`
        query AppsImages {
            mobilne: file(
                relativePath: {
                    eq: "aplikacje-mobilne-iqcode.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            mobilneMain: file(
                relativePath: {
                    eq: "aplikacje-mobilne-iqcode-iphone12.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowam: file(
                relativePath: {
                    eq: "kompleksowa-usluga.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <SubLayout
      title={(
        <>
          Aplikacje
          {' '}
          <span>Mobilne</span>
        </>
)}
      subtitle="Nowoczesne i szybkie"
      img="/img/apps-mobile-bg.jpg"
    >
      <SEO title="Aplikacje mobilne Android i iOS - iqcode Software House" description="Tworzymy nowoczesne aplikacje mobilne dla platform Android i iOS w technologiach hybrydowych React Native oraz Flutter." />
      <div style={{ boxShadow: '3px 3px 30px #00000070' }}>
        <FeatureSectionLight
          title={(
            <>
              Aplikacje
              {' '}
              <span>Mobilne</span>
              {' '}
              dla Platform Android i iOS
            </>
                      )}
          img={<div style={{ padding: '0 14%' }}><Img alt="nowoczesne aplikacje mobilne" fluid={data.mobilneMain.childImageSharp.fluid} /></div>}
          technologies={[
            { icon: <ReactIcon />, name: 'React Native' },
            { icon: <FlutterIcon />, name: 'Flutter' },
          ]}
          desc={(
            <>
              <p>
                Rynek aplikacji mobilnych rozwija się
                bardzo dynamicznie. Dzięki popularyzacji
                {' '}
                <strong>technologii hybrydowych</strong>
                {' '}
                pozwalających na zmniejszenie
                kosztów produkcji aplikacji mobilnych,
                coraz więcej średniej wielkości
                przedsiębiorstw zaczyna sięgać po ten
                typ produktów cyfrowych.
              </p>
              <p>
                Projektujemy i tworzymy aplikacje
                mobilne dla systemów Android i iOS.
                Nasze produkty cechują się znakomitym
                user experience osiągniętym dzięki
                płynnemu działaniu UI oraz jego
                intuicyjnemu layoutowi. W połączeniu z
                tworzonym przez nas backendem (aplikacją
                działającą po stronie serwera) tworzą
                kompletny produkt cyfrowy.
              </p>
            </>
                      )}
        />
      </div>
      <div className="section" id="aplikacje-mobilne">
        <ParallaxBanner
          className="phone-showcase parallax-container bg-dark overlay-holder"
          style={{ height: 'auto', backgroundColor: '#b921cb4d' }}
          layers={[{
            image: '/img/p3.jpg',
            amount: 0.4,
            props: { style: { zIndex: -1 } },
          }]}
        >
          <div className="parallax" />
          <Container>
            <div className="row">
              <div className="col m12 l4">
                <div className="phone-block">
                  <FlashIcon />
                  <h4>Błyskawicznie szybkie</h4>
                  <p>
                    Natywne technologie w połączeniu z
                    wydajnym kodem apliakcji zapewniają
                    znakomitą płynność działania UI.
                  </p>
                </div>
                <div className="phone-block">
                  <FingerprintIcon />
                  <h4>Unikalne UX</h4>
                  <p>
                    Nieszablonowe rozwiązania pozwalają
                    tworzyć wyjątkowe user experience.
                  </p>
                </div>
              </div>
              <div className="col m12 l4">
                <Img fluid={data.mobilne.childImageSharp.fluid} alt="cechy aplikacji mobilnych" />
              </div>
              <div className="col m12 l4">
                <div className="phone-block">
                  <SearchIcon />
                  <h4>App Store Optimization</h4>
                  <p>
                    Optymalizujemy aplikacje dla
                    łatwiejszego wyszukiwania ich w
                    sklepach z aplikacjami mobilnymi.
                  </p>
                </div>
                <div className="phone-block">
                  <CloudIcon />
                  <h4>Własny backend</h4>
                  <p>
                    Autorskie oprogramowanie działające
                    po stronie serwera zapewnia idealną
                    integrację.
                  </p>
                </div>
              </div>
            </div>
          </Container>
          <div className="diagonal-overlay-color" />
        </ParallaxBanner>
        <div className="technologies bg-dark outline-fix z-depth-3">
          <Container>
            <h3>
              <span>Technologie</span>
              {' '}
              których używamy
            </h3>

            <Grid container>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <ReactIcon className="tech-icon" />
                <p>React Native</p>
              </Grid>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <FlutterIcon className="tech-icon" />
                <p>Flutter</p>
              </Grid>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <SwiftIcon className="tech-icon" />
                <p>Swift</p>
              </Grid>
            </Grid>
            <Link className="btn btn-alt" to="/technologie">Więcej informacji</Link>
          </Container>
        </div>

        <div className="timeline">
          <Container>
            <h2>
              Etapy realizacji
              {' '}
              <span>Aplikacji Mobilnej</span>
            </h2>
            <h5>Jak wygląda nasza współpraca</h5>
            <div className="timeline">
              <div className="timeline-event">
                <div className="timeline-inner">
                  <h3>Określenie wymagań</h3>
                  <p>
                    Współpracę rozpoczynamy od
                    zapoznania się z wymaganiami
                    klienta. Doprecyzowujemy
                    funkcjonalność aplikacji mobilnej
                    oraz wstępnie dobieramy technologie,
                    które posłużą nam do realizacji
                    projektu.
                  </p>
                  <p>
                    Ten etap kończymy przedstawieniem
                    finalnej wyceny oraz podpisaniem
                    umowy.
                  </p>
                </div>
                <div className="timeline-num">
                  <span>01</span>
                </div>
                <img
                  className="timeline-icon"
                  src="/img/okreslenie-wymagan.svg"
                  alt="określenie wymagań aplikacji mobilnej"
                />
              </div>
              <div className="timeline-event">
                <div className="timeline-inner-right">
                  <h3>Projektowanie aplikacji</h3>
                  <p>
                    Następnym krokiem jest
                    zaprojektowanie UI. Przygotowujemy
                    projekty ekranów aplikacji, które są
                    wierną wizualizacją interfejsu
                    użytkownika gotowego produktu.
                  </p>
                  <p>
                    Po zatwierdzeniu przez klienta
                    przygotowanych przez nas projektów
                    UI, przystępujemy do implementacji.
                  </p>
                </div>
                <div className="timeline-num-right">
                  <span>02</span>
                </div>
                <img
                  className="timeline-icon"
                  src="/img/projektowanie-layoutu.svg"
                  alt="projektowanie aplikacji mobilnej"
                />
              </div>
              <div className="timeline-event">
                <div className="timeline-inner">
                  <h3>Implementacja i testy</h3>
                  <p>
                    Równolegle pracujemy nad samą
                    aplikacją działającą po stronie
                    urządzenia mobilnego jak i nad
                    backendem &#8211; programem
                    działającym po stronie serwera,
                    który obsługuje zapytania
                    użytkowników.
                  </p>
                  <p>
                    Pracę dzielimy na dwutygodniowe
                    sprinty. Na końcu każdego z nich,
                    przedstawiamy klientowi aplikację w
                    jej obecnym kształcie.
                  </p>
                </div>
                <div className="timeline-num">
                  <span>03</span>
                </div>
                <img
                  className="timeline-icon"
                  src="/img/prototypowanie-implementacja.svg"
                  alt="implementacja aplikacji mobilnych"
                />
              </div>
              <div className="timeline-event">
                <div className="timeline-inner-right">
                  <h3>Akceptacja aplikacji</h3>
                  <p>
                    Gdy implementacja zostanie
                    zakończona, udostępniamy klientowi
                    wersję beta aplikacji do testów
                    akceptacyjnych.
                  </p>
                  <p>
                    Na tym etapie korygujemy ewentualne
                    błędy i wprowadzamy drobne poprawki.
                  </p>
                </div>
                <div className="timeline-num-right">
                  <span>04</span>
                </div>
                <img
                  className="timeline-icon"
                  src="/img/akceptacja-aplikacji.svg"
                  alt="akceptacja aplikacji mobilnej przez klienta"
                />
              </div>
              <div className="timeline-event">
                <div className="timeline-inner">
                  <h3>Publikacja aplikacji</h3>
                  <p>
                    Przygotowujemy aplikację mobilną do
                    publikacji w sklepach
                    odpowiadających jej platform (Google
                    Play dla platformy Android oraz App
                    Store dla platformy iOS).
                  </p>
                  <p>
                    Przeprowadzamy analizę App Store
                    Optimization dla doboru strategii
                    umożliwiających łatwe odnalezienie
                    aplikacji w sklepach przez
                    potencjalnych użytkowników.
                  </p>
                </div>

                <div className="timeline-num">
                  <span>05</span>
                </div>
                <img
                  className="timeline-icon"
                  src="/img/publikacja.svg"
                  alt="publikacja aplikacji mobilnej"
                />
              </div>
            </div>
          </Container>
        </div>

        <div className="promo overlay-holder z-depth-3">
          <div className="diagonal-overlay-light" />
          <div className="rel">
            <h3>
              Chcesz wycenić
              {' '}
              <span>aplikację mobilną</span>
              ?
            </h3>
            <Link className="btn" to="/kontakt/">Napisz do nas!</Link>
          </div>
        </div>
        <FeatureSectionLight
          title={(
            <>
              <span>Kompleksowa </span>
              Usługa
            </>
                  )}
          mb0
          img={(
            <div style={{ padding: '10%' }}>
              <Img
                alt="nowoczesne aplikacje mobilne"
                fluid={data.kompleksowam.childImageSharp.fluid}
                imgStyle={{ width: 'auto' }}
              />
            </div>
                  )}
          desc={(
            <>
              <p>
                Prawie każda aplikacja mobilna, dla
                zapewnienia pożądanej
                funkcjonalności, wymaga odpowiednio
                przygotowanego oprogramowania
                działajacego po stronie serwera
                (
                <Link to="/aplikacje-serwerowe/">backendu</Link>
                ).
              </p>
              <p>
                Oferowane przez nas produkty
                cyfrowe, w połączeniu ze
                świadczonymi przez nas od niedawna
                usługami outsourcingu IT, tworzą
                kompletne rozwiązania z zakresu
                obsługi informatycznej
                przedsiębiorstw.
              </p>
              <p>
                Nasi klienci osczędzają czas i
                pieniądze dzięki długotrwałej
                współpracy skrojonej na miarę ich
                przedsiębiorstwa.
              </p>
            </>
                  )}
          link="Porozmawiajmy"
          to="/kontakt"
        />

        <AllServices />
      </div>
    </SubLayout>
  );
};

export default AppsPage;
